<template>
    <div>
        <div class="exam-result">
            <div class="exam-result-title" v-if="$i18n.locale == 'zh'">
                {{rexamResultInfo.exam_name_cn}}
            </div>
            <div class="exam-result-title" v-if="$i18n.locale == 'en'">
                {{rexamResultInfo.exam_name_en}}
            </div>
            <table class="exam-result-table">
                <tr>
                    <td style="width: 20%;">{{$t('home.Candidate')}}</td>
                    <td> {{rexamResultInfo.username}} </td>
                </tr>
                <tr>
                    <td>{{$t('home.KnowledgePoint')}}</td>
                    <td v-if="$i18n.locale == 'zh'">
                        {{ rexamResultInfo.exam_knowledge_cn }}
                    </td>
                    <td v-if="$i18n.locale == 'en'">
                        {{ rexamResultInfo.exam_knowledge_en }}
                    </td>
                </tr>
                <tr>
                    <td>{{$t('home.NumberOfQuestions')}}</td>
                    <td v-if="$i18n.locale == 'en'">{{ rexamResultInfo.exam_total_en }}</td>
                    <td v-if="$i18n.locale == 'zh'">{{ rexamResultInfo.exam_total_cn }}</td>
                </tr>
                <tr>
                    <td>{{$t('home.Date')}}</td>
                    <td>{{ rexamResultInfo.start_time }}</td>
                </tr>
                <tr>
                    <td>{{$t('home.Duration')}}</td>
                    <td>{{ rexamResultInfo.answer_time }}</td>
                </tr>
            </table>
            <div class="look-result">
                <button @click="$router.push({path:'/resultQuestionDetails',query:{exam_id:rexamResultInfo.exam_id,practice_id:rexamResultInfo.id}})">{{$t('home.ViewDetail')}}</button>
            </div>
        </div>
        <div class="result-analysis">
            <div class="result-analysis-title">
                {{$t('home.ScoreAnalysis')}}
            </div>
            <div class="result-analysis-box">
                <table class="result-analysis-table">
                    <tr>
                        <td>
                            {{$t('home.ScoreTotal')}}
                        </td>
                        <td>
                            <div class="progress" style="height: 35px; margin: 5px 0;">
                                <div class="progress-bar" :style="'width:'+computedBai(rexamResultInfo.score,rexamResultInfo.total_score) "></div>
                                <div class="absolute-full flex flex-center">
                                    <div class="progress-label"> {{rexamResultInfo.score}} / {{ rexamResultInfo.total_score }}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{{$t('home.CorrectQuestionsTotalQuestions')}}</td>
                        <td>
                            <div class="progress" style="height: 36px; margin: 5px 0;">
                                <div class="progress-bar" :style="'width:'+computedBai(rexamResultInfo.total_current_question,rexamResultInfo.exam_total_cn) "></div>
                                <div class="absolute-full flex flex-center">
                                    <div class="progress-label"> {{rexamResultInfo.total_current_question}} / {{ rexamResultInfo.exam_total_cn }}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="result-analysis-title" style="margin-top: 21px">
                {{$t('home.ResultByCategoryScoreTotal')}}
            </div>
            <div class="result-analysis-box">
                <table class="result-analysis-table">
                    <tr v-for="(item,index) in rexamResultInfo.classify" :key="index">
                        <td v-if="$i18n.locale == 'zh'">{{item.classify_name_cn}}</td>
                        <td v-if="$i18n.locale == 'en'">{{item.classify_name_en}}</td>
                        <td>
                            <div class="progress" style="height: 36px; margin: 5px 0;">
                                <div class="progress-bar" :style="'width:'+ computedBai(item.classify_current_score,item.classify_total_score)"></div>
                                <div class="absolute-full flex flex-center">
                                    <div class="progress-label"> {{item.classify_current_score}} / {{item.classify_total_score}}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "examResult",
        data() {
            return {
                progress1: 0.3,
                rexamResultInfo:[]
            }
        },
        created() {
            this.getExamResult(this.$route.query.id)
        },
        methods:{
            // 获取练习记录结果
            getExamResult(id){
                this.$http.post('question/performance_analysis',{
                    practice_id:id,
                }).then(res =>{
                    console.log(res)
                    this.rexamResultInfo = res.data
                })
            },
            // 计算百分比
            computedBai(num,total){
                 num = parseFloat(num);
                 total = parseFloat(total);
                if (isNaN(num) || isNaN(total)) {
                    return "-";
                }
                return total <= 0 ? "0%" : (Math.round(num / total * 10000) / 100.00)+"%";
            }
        },
        computed: {
            progressLabel1() {
                return (this.progress1 * 100).toFixed(2) + '%'
            },
        }
    }
</script>

<style scoped lang="less">
    .exam-result{
        padding: 32px 39px;
        .exam-result-title{
            font-size: 20px;
            margin-bottom: 10px;
        }
        .exam-result-table{
            width: 100%;
            tr{
                margin-bottom: 5px;
                td{
                    font-size: 16px;
                    color: #666666;
                    &:first-child{
                        vertical-align: top;
                        white-space: nowrap;
                        padding-right: 30px;
                        font-size: 18px;
                        color: #333333;
                    }
                }
            }
        }
        .look-result{
            margin-top: 10px;
            button{
                width: 110px;
                height: 50px;
                color: #ffffff;
                border: none;
                cursor: pointer;
                background: url("../assets/imgaes/pagination-bg.png") no-repeat;
                background-size: 100% 100%;
                font-size: 20px;
                border-radius: 8px;
            }
        }
    }
    .progress {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 4px;
        position: relative;

        .progress-label {
            color: #ED711C;
            font-size: 12px;
            line-height: 12px;
            font-weight: 400;
            border-radius: 4px;
            background-color: #fff;
            padding: 2px 6px;
        }

        .progress-bar {
            background-color: #ED711C;
            border-radius: 4px;
            height: 100%;
        }
    }
    .result-analysis{
        .result-analysis-title{
            font-size: 24px;
            padding: 8px 0;
            border-top: 1px solid #ED711C;
            border-bottom: 1px solid #ED711C;
            padding-left: 33px;
            margin-bottom: 10px;
        }
        .result-analysis-box{
            padding: 0 30px;
            .result-analysis-table{
                width: 100%;
                padding: 0 30px;
                tr{
                    td{
                        font-size: 18px;
                        &:last-child{
                            width: 40%;
                        }
                    }
                }
            }
        }

    }
</style>